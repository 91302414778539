import React from 'react'

const Logo = () => (
  <svg viewBox="0 0 493.19 493.19">
    <title>Simon Parisse</title>
    <path d="M360,68H144c0-13.233-10.767-24-24-24H88c-13.233,0-24,10.767-24,24h-8C25.121,68,0,93.122,0,124v192
		c0,30.878,25.121,56,56,56h304c30.879,0,56-25.122,56-56V124C416,93.122,390.879,68,360,68z M88,60h32c4.411,0,8,3.589,8,8H80
		C80,63.589,83.589,60,88,60z M360,356H56c-22.056,0-40-17.944-40-40V164h64c4.418,0,8-3.582,8-8s-3.582-8-8-8H16v-24
		c0-22.056,17.944-40,40-40h304c22.056,0,40,17.944,40,40v24h-64c-4.418,0-8,3.582-8,8s3.582,8,8,8h64v152
		C400,338.056,382.056,356,360,356z"/>
    <path d="M208,100c-66.168,0-120,53.832-120,120s53.832,120,120,120s120-53.832,120-120S274.168,100,208,100z M208,324
      c-57.346,0-104-46.654-104-104s46.654-104,104-104s104,46.654,104,104S265.346,324,208,324z"/>
    <path d="M208,132c-48.523,0-88,39.477-88,88s39.477,88,88,88s88-39.477,88-88S256.523,132,208,132z M208,292
      c-39.701,0-72-32.299-72-72s32.299-72,72-72s72,32.299,72,72S247.701,292,208,292z"/>
    <path d="M208,164c-4.418,0-8,3.582-8,8s3.582,8,8,8c22.056,0,40,17.944,40,40c0,4.418,3.582,8,8,8s8-3.582,8-8
      C264,189.122,238.879,164,208,164z"/>
    <path d="M352,140c13.233,0,24-10.766,24-24s-10.767-24-24-24s-24,10.766-24,24S338.767,140,352,140z M352,108c4.411,0,8,3.589,8,8
      s-3.589,8-8,8s-8-3.589-8-8S347.589,108,352,108z"/>
  </svg>
)

export default Logo
